<template>
  <div>
    <b-card title="Settings" v-if="settingWithLocale != null">
      <b-input-group-append style="float: right" v-show="!updateOption">
        <b-button variant="warning" @click="updateOption = !updateOption">
          Edite
          <feather-icon icon="EditIcon"/>
        </b-button>
      </b-input-group-append>

      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-row>
                <b-col md="6">
                  <table style="width: 100%">
                    <tbody style="width: 100%">
                      <tr>
                        <td>
                          <h5 class="mt-1">
                            <span style="font-weight: bold; color: #0d6efd"
                              >Setting Name :</span
                            >
                          </h5>
                        </td>
                        <td>
                          <h4 class="mt-1">
                            {{
                              settingWithLocale.attribute
                                .replace(/([A-Z,'_'])/g, ' ')
                                .trim()
                            }}
                          </h4>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <h5 class="mt-1">
                            <span style="font-weight: bold; color: #0d6efd"
                              >Status:</span
                            >
                          </h5>
                        </td>
                        <td>
                          <h5 class="mt-1">
                            <b-badge
                              variant="success"
                              v-if="settingWithLocale.active"
                            >
                              Active
                            </b-badge>
                            <b-badge variant="secondary" v-else>
                              Deactivate
                            </b-badge>
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col> </b-col>
              </b-row>
            </b-col>
            <b-col md="3" v-if="updateOption">
              <validation-provider
                #default="{ errors }"
                :name="settingWithLocale.attribute"
                rules="required"
              >

              <b-form-group label="Is Active ?" label-for="Active">

                      <b-form-checkbox switch v-model="settingWithLocale.active">
                        <p v-if="settingWithLocale.active">
                          Active To Show On The Website 
                        </p>
                        <p v-else> Deactivate Show On The Website </p>
                      </b-form-checkbox>
              </b-form-group>
                
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-col>
            <b-col md="12" v-if="settingWithLocale.data_type == 'text'">
              <b-row
                v-for="(
                  setting, settingIndex
                ) in settingWithLocale.staticPagesLocaleArray"
                :key="settingIndex"
              >
                <b-col md="12">
                  <h5 v-if="setting.locale == 'ar'">
                  
                    <span style="font-weight: bold;color: coral;">Arabic Data </span>
                  </h5>
                  <h5 v-else>
                    
                    <span style="font-weight: bold;color: coral;">English Data</span>
                  </h5>
                </b-col>
                <b-col md="10">
                  <vue-editor
                    :editor-toolbar="customToolbar"
                    :disabled="!updateOption"
                    id="value"
                    v-model="setting.text"
                    @imageAdded="handleImageAdded"
                    rows="20"
                  ></vue-editor>
                </b-col>

                <b-col md="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>
            <b-col  md="12"  v-else-if="settingWithLocale.data_type == 'single_media'" >
              <b-row>
                <b-col md="12" v-if="!updateOption">
                  <b-row
                    v-for="(
                      settingLocale, settingIndex
                    ) in settingWithLocale.singleMediaData"
                    :key="settingIndex"
                  >
                    <b-col md="12">
                      <h4 v-if="settingLocale.locale == 'ar'">
                        <span style="font-weight: bold;color: coral;">Arabic Data </span>
                      </h4>
                      <h4 v-else>
                        <span style="font-weight: bold;color: coral;">English Data</span>
                      </h4>
                    </b-col>
                    <b-col
                      md="4"
                      v-for="(
                        singleMediaSize, settingSizeIndex
                      ) in settingLocale.single_media_array"
                      :key="settingSizeIndex"
                    >
                      <b-row>
                        <b-col md="12" style="text-align: -webkit-center">
                          <b-card
                            :title="singleMediaSize.size + ' Size'"
                            :img-src="singleMediaSize.image"
                            img-alt="Image"
                            img-top
                            tag="article"
                            style="max-width: 20rem"
                            class="mb-2"
                          >
                            <!-- <b-img class="w-auto" :src="singleMediaSize.image"/> -->
                            <b-card-text>
                              <h5>Alt : {{ singleMediaSize.alt }}</h5>
                              <h5>Link : {{ singleMediaSize.link }}</h5>
                            </b-card-text>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col md="12" v-if="settingIndex+1 != settingWithLocale.singleMediaData.length">
                        <hr class="w-100 border-gray my-1" style="border-top: 5px solid #ebe9f1;" />
                    </b-col>
                  </b-row>
                </b-col>

                <b-col v-else>
                  <!--  Locale For Media Array -->
                  <b-row
                        v-for="(
                          mediaLocale, indexLocale
                        ) in settingWithLocale.singleMediaData"
                        :key="indexLocale"
                      >
                        <b-col md="12">
                          <app-collapse
                            id="faq-payment-qna"
                            type="margin"
                            class="mt-0 mb-0"
                          >
                            <!-- Store Pickup Request  For Order -->
                            <app-collapse-item
                              :title="
                                mediaLocale.locale == 'ar'
                                  ? 'Media Data Arabic'
                                  : 'Media Data English '
                              "
                              style="color: coral;"
                              :key="indexLocale"
                            >
                              <b-row
                                v-for="(
                                  singleMediaSize, indexSize
                                ) in mediaLocale.single_media_array"
                                :key="indexSize"
                              >
                                <b-col md="6">
                                  <b-row>
                                    <b-col
                                      md="12"
                                      style="
                                        text-align: center;
                                        align-self: center;
                                      "
                                    >
                                      <h4 style="text-decoration-line: underline;">{{ singleMediaSize.size }} Size</h4>
                                    </b-col>

                                    <b-col md="12">
                                      <b-form-group
                                        label="Image"
                                        label-for="image_single_media"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="image_single_media"
                                          rules="required"
                                        >
                                          <b-form-file
                                            v-model="singleMediaSize.newImage"
                                            @change="
                                              ChangeSingleImageValue(
                                                $event,
                                                indexLocale,
                                                indexSize
                                              )
                                            "
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                            accept="image/*"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="12">
                                      <b-form-group
                                        label="Alt"
                                        label-for="alt_single_media"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="alt_single_media"
                                          rules="required"
                                        >
                                          <b-form-input
                                            v-model="singleMediaSize.alt"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="12">
                                      <b-form-group
                                        label="Redirect Link"
                                        label-for="link_single_media"
                                      >
                                        <b-form-input
                                          v-model="singleMediaSize.link"
                                        />
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <b-col
                                  cols="6"
                                  v-if="singleMediaSize.image != null"
                                >

                                  <b-img
                                    class="w-auto"
                                    style="max-width: 75%"
                                    :src="singleMediaSize.image"
                                  />
                                </b-col>

                                <b-col cols="12"> <hr /> </b-col>
                              </b-row>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>

                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" v-else-if="settingWithLocale.data_type == 'media_array'" >
              <b-row>
                <b-col  md="12" v-if="!updateOption">
                  
                  <b-row v-for="( settingLocaleArray, settingIndex ) in settingWithLocale.mediaLocaleArrayData" :key="settingIndex">
                    <b-col md="12">
                      <h4 v-if="settingLocaleArray.locale == 'ar'">
                        <span style="font-weight: bold; color: coral;">Arabic Data </span>
                      </h4>
                      <h4 v-else>
                        <span style="font-weight: bold; color: coral;">English Data</span>
                      </h4>
                    </b-col>
    
                    <b-col
                      md="12"
                      v-for="(
                        sizeMediaArray, settingSizeIndex
                      ) in settingLocaleArray.sizeMediaArray"
                      :key="settingSizeIndex"
                    >
                      <b-row>
                        <b-col md="12" style="text-align: -webkit-center">
                          <h2>{{ sizeMediaArray.size }}</h2>
                          <swiper
                            class="swiper-autoplay"
                            :options="swiperOptions"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          >
                            <swiper-slide
                              v-for="(data, index) in sizeMediaArray.imageArray"
                              :key="index"
                            >
                              <b-img
                                @click="imgIndex = index"
                                width="300px"
                                height="auto"
                                :src="data.image"
                                style="display: block; margin: auto"
                              />
                            </swiper-slide>
                            <div slot="pagination" class="swiper-pagination" />
                          </swiper>
                        </b-col>
                        <b-col md="12" v-if="settingSizeIndex+1 != settingLocaleArray.sizeMediaArray.length">
                        <hr class="w-100 border-gray my-1" style="border-top: 4px solid #ebe9f1;" />
                        </b-col>
                      </b-row>
                    </b-col>   
                    <b-col md="12" v-if="settingIndex+1 != settingWithLocale.mediaLocaleArrayData.length">
                        <hr class="w-100 border-gray my-1" style="border-top: 5px solid rgb(244 221 206);" />
                        </b-col>
                  </b-row>
                </b-col>

                <b-col v-else>

                  <b-row v-for="(mediaLocaleArray, mediaIndexLocale) in settingWithLocale.mediaLocaleArrayData" :key="mediaIndexLocale">
                        <b-col md="12">
                          <app-collapse
                            id="faq-payment-qna"
                            type="margin"
                            class="mt-0 mb-0"
                          >
                            <!-- Index Locale For Media Array -->
                            <app-collapse-item
                              :title="
                                mediaLocaleArray.locale == 'ar'
                                  ? 'Media Data Arabic'
                                  : 'Media Data English'
                              "
                              style="color: coral;"
                              :key="mediaIndexLocale"
                            >
                              <!-- Size Array -->
                              <b-row
                                v-for="(
                                  arrayMediaSize, arrayIndexSize
                                ) in mediaLocaleArray.sizeMediaArray"
                                :key="arrayIndexSize"
                              >
                                <b-col md="12">
                                  <!-- Images Array -->
                                  <app-collapse
                                    id="faq-payment-qna"
                                    type="margin"
                                    class="mt-0 mb-0"
                                  >
                                    <!-- Index Locale For Media Array -->
                                    <app-collapse-item
                                      :title="arrayMediaSize.size + '  Size'"
                                      :key="
                                        arrayMediaSize.size + arrayIndexSize
                                      "
                                      style="color: #6e6b7b;"
                                    >
                                      <!-- draggable -->
                                      <draggable
                                        :list="arrayMediaSize.imageArray"
                                        tag="ul"
                                        group="media"
                                        class="list-group list-group-flush cursor-move"
                                      >
                                        <b-row v-for="( arrayMedia, arrayIndex) in arrayMediaSize.imageArray" :key="arrayIndex">
                                          <b-col md="6">
                                            <b-row>
                                              <b-col
                                                md="12"
                                                style="
                                                  text-align: center;
                                                  align-self: center;
                                                  text-decoration: underline;
                                                "
                                              >
                                                <h4>
                                                  Image {{ arrayIndex + 1 }}
                                                </h4>
                                              </b-col>

                                              <b-col md="12">
                                                <b-form-group
                                                  label="Image"
                                                  label-for="image_single_media"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="image_single_media"
                                                    rules="required"
                                                  >
                                                    <b-form-file
                                                      v-model="arrayMedia.file"
                                                      @change="
                                                        ChangeMediaImageValue(
                                                          $event,
                                                          mediaIndexLocale,
                                                          arrayIndexSize,
                                                          arrayIndex
                                                        )
                                                      "
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                      accept="image/*"
                                                    />
                                                    <small
                                                      class="text-danger"
                                                      >{{ errors[0] }}</small
                                                    >
                                                  </validation-provider>
                                                </b-form-group>
                                              </b-col>

                                              <b-col md="12">
                                                <b-form-group
                                                  label="Alt"
                                                  label-for="alt_single_media"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="alt_single_media"
                                                    rules="required"
                                                  >
                                                    <b-form-input
                                                      v-model="arrayMedia.alt"
                                                      :state="
                                                        errors.length > 0
                                                          ? false
                                                          : null
                                                      "
                                                    />
                                                    <small
                                                      class="text-danger"
                                                      >{{ errors[0] }}</small
                                                    >
                                                  </validation-provider>
                                                </b-form-group>
                                              </b-col>

                                              <b-col md="12">
                                                <b-form-group
                                                  label="Redirect Link"
                                                  label-for="link_single_media"
                                                >
                                                  <b-form-input
                                                    v-model="arrayMedia.link"
                                                  />
                                                </b-form-group>
                                              </b-col>

                                              <b-col
                                                md="12"
                                                class="mb-50"
                                                style="
                                                  text-align: center;
                                                  align-self: center;
                                                "
                                              >
                                                <b-button
                                                  v-ripple.400="
                                                    'rgba(234, 84, 85, 0.15)'
                                                  "
                                                  v-show="arrayIndex != 0"
                                                  variant="outline-danger"
                                                  class="mt-0 mt-md-2"
                                                  @click="
                                                    removeMediaFromArray(
                                                      $event,
                                                      mediaIndexLocale,
                                                      arrayIndexSize,
                                                      arrayIndex
                                                    )
                                                  "
                                                >
                                                  <feather-icon
                                                    icon="XIcon"
                                                    class="mr-25"
                                                  />
                                                  <span>Remove</span>
                                                </b-button>
                                              </b-col>
                                            </b-row>
                                          </b-col>
                                          <b-col
                                            cols="6"
                                            style="text-align: center;align-self: center;"
                                            v-if="
                                              arrayMedia.image != null
                                            "
                                          >
                                            <div></div>

                                            <b-img
                                              class="w-auto"
                                              style="max-width: 75%"
                                              :src="arrayMedia.image"
                                            />
                                          </b-col>

                                          <b-col cols="12"> <hr /> </b-col>
                                        </b-row>
                                      </draggable>
                                      <!-- add new button -->
                                      <b-col sm="2">
                                        <b-button
                                          v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                          variant="outline-warning"
                                          block
                                          @click="
                                            addNewMediaToArray(
                                              $event,
                                              mediaIndexLocale,
                                              arrayIndexSize
                                            )
                                          "
                                        >
                                          <feather-icon
                                            icon="PlusIcon"
                                            class="mr-25"
                                          />
                                          <span>Add New Media</span>
                                        </b-button>
                                      </b-col>
                                      <b-col cols="12">
                                        <hr />
                                      </b-col>
                                    </app-collapse-item>
                                  </app-collapse>
                                </b-col>
                              </b-row>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>


                </b-col>
              </b-row>
            </b-col>

            <b-col lg="4" md="2" class="my-1" v-if="updateOption">
            
              <b-button
                variant="primary"
                class="mx-1"
                @click="updateSetting($event, settingWithLocale)"
                >Submit</b-button
              >
              <b-button
                variant="secondary"
                class="mx-1"
                @click="updateOption = !updateOption"
                >Close</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="$store.state.Setting.showDismissibleAlert"
      @dismissed="$store.state.Setting.showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul
          v-for="(values, index) in $store.state.Setting.errors_back"
          :key="index"
        >
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
  
  <script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { required, url } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import { VueEditor } from 'vue2-editor'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
export default {
  components: {
    VueEditor,
    Swiper,
    SwiperSlide,
    draggable,
    AppCollapse,
    AppCollapseItem,
  },

  mixins: [heightTransition],
  data() {
    return {
      settingWithLocale:null,
      required,
      file:null,
      required,
      url,
      updateOption: false,
      editableIndex: null,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      
    }
  },
  computed: {
    customToolbar() {
      return this.$store.state.Setting.customToolbar
    },
    boolOptions() {
      return this.$store.state.Setting.boolOptions
    },
  },

  async created() {
    if (this.$route.params.action == 'update') {
      this.updateOption = true
    }
    await this.fetchData()
    this.settingWithLocale = { ...this.$store.state.Setting.settingWithLocale }; 
    
    // console.log(this.settingWithLocale)
  },
  methods: {
    ChangeSingleImageValue(e, indexLocale, indexSize) {

      const image = e.target.files[0]
      if (e != null) {
        this.settingWithLocale.singleMediaData[
          indexLocale
        ].single_media_array[indexSize].image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)
        
      }

    },
    ChangeMediaArrayImageValue(e, indexLocale, indexSize ,arrayIndex ) {


      const image = e.target.files[0]
      if (e != null) {
        this.settingWithLocale.singleMediaData[
          indexLocale
        ].single_media_array[indexSize].image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)
        
      }

    },
    addNewMediaToArray(e, indexLocale, indexSize) {
      this.settingWithLocale.mediaLocaleArrayData[
      indexLocale
      ].sizeMediaArray[indexSize]['imageArray'].push({
        image: null,
        imageBase64: null,
        alt: null,
        link: null,
      })
      
    },
    // remove media from array
    removeMediaFromArray(e, indexLocale, arrayIndexSize, arrayIndex) {
      if (
        this.settingWithLocale.mediaLocaleArrayData[indexLocale]
          .sizeMediaArray[arrayIndexSize].length <= 1
      ) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.settingWithLocale.mediaLocaleArrayData[
        indexLocale
      ].sizeMediaArray[arrayIndexSize].imageArray.splice(arrayIndex, 1)

      
      }
    },
    ChangeMediaImageValue(e, indexLocale, indexSize, indexArray) {
      const image = e.target.files[0]
      if (e != null) {
        this.settingWithLocale.mediaLocaleArrayData[
          indexLocale
        ].sizeMediaArray[indexSize].imageArray[indexArray].image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)


        fileReader.onload = (e) => {
          this.settingWithLocale.mediaLocaleArrayData[
          indexLocale
        ].sizeMediaArray[indexSize].imageArray[indexArray].imageBase64 = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.$store.commit('Setting/storeImageEditor', {
        file,
        Editor,
        cursorLocation,
        resetUploader,
      })
    },
    async fetchData() {
      await this.$store.dispatch(
        'Setting/ShowSettingWithLocale',
        this.$route.params.attribute
      )
    },
    async updateSetting(e, item) {
   
      
      await this.$store.dispatch('Setting/updateSetting', item)
      if (this.$store.state.Setting.showDismissibleAlert == false) {
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        })
        this.updateOption = false
      } else {
        this.$swal({
          position: 'center',
          icon: 'error',
          title: 'Error!',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    },
  },
  beforeDestroy() {
    // console.log('destroy')
    this.$store.commit('Setting/clearSettingWithLocaleData')
    this.$store.commit('Setting/removeErrorsData')
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

  
  <style scoped lang="scss">
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
</style>
  